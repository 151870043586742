body{
  background-color: #eff2f2
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-table-cell{
  cursor: pointer;
}

.ant-menu-item-selected {
  background-color: #571e1e !important;
}

iframe {
  display: none;
}

.ant-statistic .ant-statistic-title {
  color: rgba(0,0,0,0.75) !important;
  font-weight: 500;
}

.ant-tag {
  padding: 2px 8px;
}

.ant-message {
  white-space: pre;
}

.ant-message-notice-content:has(.ant-message-info) {
  border: 1px solid #1677ff !important;
  background-color: #a7c9f8 !important;
}

.ant-message-notice-content:has(.ant-message-success) {
  border: 1px solid #52c41a !important;
  background-color: #bce3a8 !important;
}

.ant-message-notice-content:has(.ant-message-warning) {
  border: 1px solid  #faad14 !important;
  background-color: #f6dba6 !important;
}

.ant-message-notice-content:has(.ant-message-error) {
  border: 1px solid  #ff4d4f !important;
  background-color: #f8babb !important;
}

:where(.css-dev-only-do-not-override-6qpw3d).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: rgba(125, 43, 43,0.25);
  border-color: rgba(125, 43, 43,0.25);
}

.lexy-link {
  font-weight: 650; 
  font-size: 16px;
}

.lexy-link:hover {
  text-decoration: solid underline #311111 2px;
  text-underline-offset: 3px;
}